.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15%;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(50px);
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.header ul {
  list-style: none;
  display: flex;
  height: 100%;
}

.header ul li a {
  display: inline-block;
  height: 100%;
  line-height: 60px;
}

.header ul li.active {
  border-bottom: 5px solid #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.header .logo {
  flex: 92px;
  padding-top: 7px;
}

.header .navigator {
  flex: 1;
  color: #fff;
  font-weight: 700;
}

.header .navigator li a {
  padding: 0 .5rem;
}

.header .shortcut {
  flex: 1;
}

.header .shortcut ul {
  justify-content: flex-end;
}

.header .shortcut ul li {
  margin: 0 1rem;
}

.header .shortcut img {
  width: 40px;
  margin-top: 10px;
}