#contact_us {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 48px;
}

#contact_us .title {
  text-align: center;
  margin-bottom: 48px;
}

#contact_us .title h1 {
  display: inline-block;
  background: url(../../assets/imgs/frame.png) no-repeat;
  width: 340px;
  height: 67px;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  text-shadow: 1px 1px 5px black;
}

#contact_us .follow_us .social_links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#contact_us .follow_us .social_links a {
  margin: 0 1rem;
  box-shadow: 1px 1px 20px 1px #000;
  border-radius: 50%;
}

#contact_us .follow_us p {
  color: #fff;
  font-size: 18px;
  text-shadow: 1px 1px 2px #000;
  margin: .5rem 0 24px 0;
}

#contact_us .content {
  display: flex;
  width: 680px;
  background: linear-gradient(to right, rgba(30, 87, 153, .3) 0%, rgba(131, 195, 153, .3) 100%);
  padding: 24px;
  border-radius: 12px;
}

#contact_us .content .corp_logo {
  box-shadow: 1px 1px 20px 0 #000;
  border-radius: 12px;
}

#contact_us .content .right_box {
  flex: 1;
  display: flex;
  flex-direction: column;
}

#contact_us .content .right_box h3 {
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: 32px;
  font-style: italic;
  margin-bottom: .5rem;
  text-shadow: 1px 1px 3px #000;
}

#contact_us .right_box p {
  text-align: left;
  padding-left: 24px;
  color: #fff;
  font-weight: 600;
}

#contact_us .right_box p span {
  margin-right: 1rem;
}
