#heros {
  margin-bottom: 48px;
}

#heros .title {
  text-align: center;
  margin-bottom: 48px;
}

#heros .title h1 {
  display: inline-block;
  background: url(../../assets/imgs/frame.png) no-repeat;
  width: 340px;
  height: 67px;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  text-shadow: 1px 1px 5px black;
}

#heros .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#heros .row .item {
  background: linear-gradient(to right, rgba(30,87,153,.3) 0%,rgba(32,124,202,.3) 43%,rgba(41,137,216,.3) 60%,rgba(125,185,232,.3) 100%); 
  text-align: center;
  margin: 12px 48px 12px 48px;
  padding: 12px;
  border-radius: 12px;
}

#heros .item h3 {
  padding: .5rem;
  background: linear-gradient(to bottom, rgba(219,105,105,1) 1%,rgba(223,0,0,1) 100%);
  color: #FCC94B;
  font-size: 18px;
  font-weight: 700;
  font-style: italic;
  border-radius: 50px;
  margin: .5rem 0;
  letter-spacing: 2px;
  text-shadow: 1px 1px 1px #f7b500;
}

#heros .item p {
  color: #fff;
  font-weight: 700;
}

#heros .item img {
  max-height: 600px;
}