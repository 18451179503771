#home {
  border-radius: 12px;
  padding: 24px 48px;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(50px);
  margin: 48px 0;
}

#home .kv .game_logo,
#home .kv .game_slogan {
  text-align: center;
}

#home .kv img {
  display: inline-block;
}

#home .kv .game_description {
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
}