#download {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 48px;
}

#download .title {
  text-align: center;
  margin-bottom: 48px;
}

#download .title h1 {
  display: inline-block;
  background: url(../../assets/imgs/frame.png) no-repeat;
  width: 340px;
  height: 67px;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  text-shadow: 1px 1px 5px black;
}


#download .content {
  display: flex;
  width: 600px;
  background: linear-gradient(to right, rgba(30, 87, 153, .3) 0%, rgba(131, 195, 153, .3) 100%);
  padding: 24px;
  border-radius: 12px;
}

#download .content .app_logo {
  box-shadow: 1px 1px 20px 0 #000;
  border-radius: 12px;
}

#download .content .right_box {
  flex: 1;
  display: flex;
  flex-direction: column;
}

#download .content .right_box h3 {
  color: #fff;
  font-weight: 700;
  font-size: 32px;
  font-style: italic;
  margin-bottom: .5rem;
  text-shadow: 1px 1px 3px #000;
}

#download .right_box .links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#download .right_box .links img {
  margin: 0 .5rem;
}